<template>
  <div @click="jumpTool">
    <div class="centerText">点击此处跳往发票对比工具</div>
  </div>
</template>

<script setup>
const jumpTool = () => {
  window.open(
    "https://exchange-business.kjgoods.com/#/import/invoice/invoice_contrast"
  );
};
</script>

<style scoped>
.centerText {
  cursor: pointer;
  margin-top: 200px;
  margin-left: 50%;
  font-size: 16px;
  transform: translateX(-100px);
  text-decoration: underline;
}
</style>
